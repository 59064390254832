<template>
  <div class="flex justify-center my-8"> 
  <Slider 
        v-model="modelValue" 
        :min="minValue" 
        :max="maxValue" 
        :tooltips="false"
        :classes="{
          target: 'relative box-border select-none touch-none tap-highlight-transparent touch-callout-none disabled:cursor-not-allowed',
          focused: 'slider-focused',
          ltr: 'slider-ltr',
          rtl: 'slider-rtl',
          horizontal: 'slider-horizontal h-1.5',
          vertical: 'slider-vertical w-1.5 h-80',
          textDirectionRtl: 'slider-txt-rtl',
          textDirectionLtr: 'slider-txt-ltr',
          base: 'w-[160px] h-1 relative z-1 bg-gray-200 rounded',
          connects: 'w-full h-full relative overflow-hidden z-0 rounded',
          connect: 'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-secondary cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-gray-400 disabled:cursor-not-allowed',
          origin: 'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
          handle: 'absolute rounded-full bg-secondary border-0 shadow-slider cursor-grab focus:outline-none h:w-4 h:h-4 h:-top-1.5 h:-right-2 txt-rtl-h:-left-2 txt-rtl-h:right-auto v:w-4 v:h-4 v:-top-2 v:-right-1.25 disabled:cursor-not-allowed focus:ring focus:ring-green-500 focus:ring-opacity-30',
          handleLower: 'slider-hande-lower',
          handleUpper: 'slider-hande-upper',
          touchArea: 'h-[30px] w-[30px]',
          active: 'slider-active shadow-slider-active cursor-grabbing',
          draggable: 'cursor-ew-resize v:cursor-ns-resize',
          tap: 'slider-state-tap',
          drag: 'slider-state-drag',
        }" 
      />
    </div>
</template>
<script setup>
  import Slider from '@vueform/slider'
const props = defineProps({
    stepValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 0
    },
    minValue: {
      type: Number,
      default: 0
    }
})
const emit = defineEmits(['onChange']);

const modelValue = computed({
  get: () => props.stepValue,
  set: (value) => {
    emit("onChange", value);
  },
});

</script>

