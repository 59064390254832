<template>
<div> 
<Carousel :items-to-show="items2Show" :wrap-around="false" v-model="currentSlide" :touchDrag="true">
    <Slide v-for="product in products" :key="product.id" class="transition-shadow">
         <div class="carousel__item  cursor-pointer duration-400 ease-out h-full w-full mx-1.5">
      
          <ProductCard :item="product" :showTurnAroundTime="false" viewTestButtonText="View test" :isHomePage="true" :displayService="displayService"  />
      </div>
    </Slide>
    <template #addons>

    <ProductSliderRange :stepValue="currentSlide" :minValue="0" :maxValue="(products.length-1)" @onChange="onTabChange"></ProductSliderRange>
    </template>
  </Carousel>
</div>
</template>


<style>
.carousel__track {
  padding-bottom: 5px !important;
}
</style>

<script setup>
import { Carousel, Pagination, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
 
const props = defineProps({
  products: Array,
  displayService: {
    type: Boolean,
    default: false,
  },
});
const currentSlide = ref(0)

function onTabChange(value) {
  currentSlide.value = Number(value)
}

const items2Show = shallowRef(1.05);
const handleScreenResize = () => {
  items2Show.value = (useScreenSize("md")) ? 1.05 : 2.05;
}

onMounted(() => {
  window.addEventListener('resize', handleScreenResize);
});
onUnmounted(() => {
  window.removeEventListener('resize', handleScreenResize);
});
</script>


